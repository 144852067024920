import React from 'react'
import { Link } from 'gatsby'
import Container from './container'
import * as styles from './other-work.module.css'
import ProductCardSmall from './product-card-small'

const OtherWork = ({ otherWork }) => {
  if (!otherWork) return null
  if (!Array.isArray(otherWork)) return null

  return (
    <Container>
      <div className={styles.otherWork}>
        {otherWork.map((otherWorkPiece) => {
          return (
            <Link to={otherWorkPiece.url} target="_blank">
                <ProductCardSmall
                    title={otherWorkPiece.title}
                    subtitle={otherWorkPiece.subtitle}
                    image={otherWorkPiece.image}
                />
            </Link>
          )
          })}
      </div>
    </Container>
  )
}

export default OtherWork
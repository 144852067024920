import React from 'react'
import * as styles from './product-card.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductCard = ({ title, subtitle, image }) => (
  <div className={styles.productCard}>
      <div className={styles.titles}>
          <h5 className={styles.subtitle}>{subtitle}</h5>
          <h3 className={styles.title}>{title}</h3>
      </div>
      <GatsbyImage className={styles.image} alt="" image={image.gatsbyImageData} />
  </div>
)

export default ProductCard

import React from 'react'
import Container from './container'
import * as styles from './home-hero.module.css'

const HomeHero = ({ title, subtitle }) => (
  <Container>
    <div className={styles.homeHero}>
      <p className={styles.subtitle}>{subtitle}</p>
      <h1 className={styles.title}>{title}</h1>
    </div>
  </Container>
)

export default HomeHero
import React from 'react'
import { Link } from 'gatsby'
import Container from './container'
import * as styles from './case-studies.module.css'
import ProductCard from './product-card'

const CaseStudies = ({ caseStudies }) => {
  if (!caseStudies) return null
  if (!Array.isArray(caseStudies)) return null

  return (
    <Container>
      <div className={styles.caseStudies}>
        {caseStudies.map((caseStudy) => {
          return (
            <Link to={`/${caseStudy.slug}/`}>
                <ProductCard
                    title={caseStudy.title}
                    subtitle={caseStudy.subtitle}
                    image={caseStudy.image}
                />
            </Link>
          )
          })}
      </div>
    </Container>
  )
}

export default CaseStudies

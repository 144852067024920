import React from 'react'

const ColoredContainer = ({ children, as = 'div' }) => {
  const Tag = as

  return (
    <Tag
      style={{
        backgroundColor: 'var(--background-accent)',
      }}
    >
      {children}
    </Tag>
  )
}

export default ColoredContainer

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/home-hero'
import ShowcaseImage from '../components/showcase-image'
import SpacerLarge from '../components/spacer-large'
import SpacerRegular from '../components/spacer-regular'
import TextBlock from '../components/text-block'
import CaseStudies from '../components/case-studies'
import OtherWork from '../components/other-work'
import Step from '../components/step'
import ColoredContainer from '../components/colored-container'
import SpacerMedium from '../components/spacer-medium'
import TextBlockWithImage from '../components/text-block-with-image'

class RootIndex extends React.Component {
  render() {
    const caseStudies = get(this, 'props.data.allContentfulCaseStudyCard.nodes')
    const otherWork = get(this, 'props.data.allContentfulProductCard.nodes')
    const [hero] = get(this, 'props.data.allContentfulHomeHero.nodes')
    const [showcaseImage] = get(this, 'props.data.showcaseImage.nodes')
    const [intro] = get(this, 'props.data.intro.nodes')
    const [caseStudyHeader] = get(this, 'props.data.caseStudyHeader.nodes')
    const [designAndCode] = get(this, 'props.data.designAndCode.nodes')
    const [processHeader] = get(this, 'props.data.processHeader.nodes')
    const [otherWorkHeader] = get(this, 'props.data.otherWorkHeader.nodes')
    const [processStepOne] = get(this, 'props.data.processStepOne.nodes')
    const [processStepTwo] = get(this, 'props.data.processStepTwo.nodes')
    const [processStepThree] = get(this, 'props.data.processStepThree.nodes')
    const [processStepFour] = get(this, 'props.data.processStepFour.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero
          title={hero.title}
          subtitle={hero.subtitle}
        />
        <ShowcaseImage image={showcaseImage.image}/>
        <SpacerMedium/>
        <TextBlock 
          title={intro.title}
          subtitle={intro.subtitle.subtitle}
        />
        <SpacerMedium/>
        <ColoredContainer>
        <SpacerMedium/>
        <TextBlock 
          title={caseStudyHeader.title}
          subtitle={caseStudyHeader.subtitle.subtitle}
        />
        <SpacerRegular/>
        <CaseStudies caseStudies={caseStudies}/>
        <SpacerMedium/>
        </ColoredContainer>
        <SpacerMedium/>
        <TextBlockWithImage
          title={designAndCode.title}
          subtitle={designAndCode.subtitle.subtitle}
          image={designAndCode.image}
          leftImage={designAndCode.leftImage}
        />
        <SpacerMedium/>
        <TextBlock 
          title={processHeader.title}
          subtitle={processHeader.subtitle.subtitle}
        />
        <SpacerRegular/>
        <Step
          title={processStepOne.title}
          description={processStepOne.description.description}
        />
        <Step
          title={processStepTwo.title}
          description={processStepTwo.description.description}
        />
        <Step
          title={processStepThree.title}
          description={processStepThree.description.description}
        />
        <Step
          title={processStepFour.title}
          description={processStepFour.description.description}
        />
        <SpacerLarge/>
        <TextBlock 
          title={otherWorkHeader.title}
          subtitle={otherWorkHeader.subtitle.subtitle}
        />
        <SpacerRegular/>
        <OtherWork otherWork={otherWork}/>
        <SpacerLarge/>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHomeHero(
      filter: { contentful_id: { eq: "ZrHGiG2K1AcufPMSzAlRo" } }
    ) {
      nodes {
        title
        subtitle
      }
    }
    showcaseImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "3mOOdcltSKgCgWV3p45eMD" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    allContentfulCaseStudyCard(sort: { fields: [title] }) {
      nodes {
        title
        subtitle
        slug
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    allContentfulProductCard {
      nodes {
        title
        subtitle
        url
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            height: 630
          )
        }
      }
    }
    intro: allContentfulTextBlock(
      filter: { contentful_id: { eq: "68nUGxI4DSPeJgHPyZMkAG" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    caseStudyHeader: allContentfulTextBlock(
      filter: { contentful_id: { eq: "1VE9H6M6YKIoc79O7vG84S" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    processHeader: allContentfulTextBlock(
      filter: { contentful_id: { eq: "4hAfRLlWtipGzP5PbOhIh6" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    otherWorkHeader: allContentfulTextBlock(
      filter: { contentful_id: { eq: "3qofxefKHimRHHJ7NM1I2f" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    designAndCode: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "5QE4qVb1EHuGftNCncaOu" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    processStepOne: allContentfulStep(
      filter: { contentful_id: { eq: "61V6lKCqIGwN8mQUQTT3X6" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
    processStepTwo: allContentfulStep(
      filter: { contentful_id: { eq: "6XD5TmBWtiv7h3Qd1ELU7W" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
    processStepThree: allContentfulStep(
      filter: { contentful_id: { eq: "5rs3YCzmvlXDGJ3s4KZPUL" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
    processStepFour: allContentfulStep(
      filter: { contentful_id: { eq: "6YpI9EfF9T5iGsB4xwZxfT" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
  }
`
